import React, { useEffect, useState } from 'react';
import { fetchContactUsData } from '../service/AdminService';

const ContactUsAdmin = () => {
    const [contactUsData, setContactUsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [pageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedMessage, setSelectedMessage] = useState(null);

    useEffect(() => {
        const loadContactUsData = async () => {
            setLoading(true);
            try {
                const response = await fetchContactUsData(page, pageSize);
                setContactUsData(response.messages || []);
                setTotalCount(response.total_count || 0);
                if (response.messages.length > 0) {
                    setSelectedMessage(response.messages[0]); // Select the first message by default
                }
            } catch (error) {
                console.error('Error loading contact us data:', error);
                setError('Failed to load data');
            } finally {
                setLoading(false);
            }
        };

        loadContactUsData();
    }, [page, pageSize]);

    const handleNextPage = () => {
        if ((page + 1) * pageSize < totalCount) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        setPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    const handleMessageClick = (message) => {
        setSelectedMessage(message);
    };

    return (
        <div className="container">
            <h1>Contact Us Messages</h1>

            <div className="grid">
                <div className="message-list">
                    <h2>Messages</h2>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        contactUsData.map(contact => (
                            <div
                                key={contact.id}
                                className={`message-item ${selectedMessage?.id === contact.id ? 'selected' : ''}`}
                                onClick={() => handleMessageClick(contact)}
                            >
                                <p className="subject">
                                    <b>{contact.subject}:</b> {contact.name}
                                    <span className="date">{new Date(contact.createdAt).toLocaleDateString()}</span>
                                </p>
                            </div>
                        ))
                    )}
                </div>

                <div className="message-detail">
                    {selectedMessage ? (
                        <>
                            <h2>{selectedMessage.subject}</h2>
                            <p><strong>From:</strong> {selectedMessage.name} ({selectedMessage.email})</p>
                            <p><strong>Message:</strong></p>
                            <p>{selectedMessage.message}</p>
                            <p><strong>Status:</strong> {selectedMessage.processed}</p>
                            <p><strong>Created At:</strong> {new Date(selectedMessage.createdAt).toLocaleString()}</p>
                        </>
                    ) : (
                        <p>Select a message to see the details.</p>
                    )}
                </div>
            </div>

            <div className="pagination">
                <button onClick={handlePreviousPage} disabled={page === 0}>
                    Previous
                </button>
                <button onClick={handleNextPage} disabled={(page + 1) * pageSize >= totalCount}>
                    Next
                </button>
            </div>

            {error && <div className="error">{error}</div>}

            <style jsx>{`
                .container {
                    max-width: 1200px;
                    padding: 10px;
                    background-color: #f7fff7; /* Light green background */
                    border-radius: 10px;
                    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
                    font-family: 'Arial', sans-serif;
                }

                h1 {
                    text-align: left;
                    color: #2c3e50; /* Darker color for headers */
                    margin-bottom: 15px;
                }

                .grid {
                    display: grid;
                    grid-template-columns: 1fr 2fr;
                    gap: 20px;
                }

                .message-list {
                    background-color: #ffffff; /* White background for the list */
                    border-radius: 10px;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                    padding: 2px; 
                }

                .message-item {
                    padding: 4px;
                    border-bottom: 1px solid #ddd;
                    cursor: pointer;
                    transition: background 0.3s;
                }

                .message-item:hover {
                    background-color: #e0f7fa; /* Light blue on hover */
                }

                .message-item.selected {
                    background-color: #b2ebf2; /* Light green highlight */
                }

                .subject {
                    font-size: 14px; /* Smaller font size */
                    line-height: 1.2; /* Line height for multiline */
                    margin:4px;
                    max-width: 300px; /* Fixed width for the subject */
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal; /* Allow multiline */
                    display: inline-block; /* Allow text to be truncated */
                }

                .date {
                    font-size: 12px; /* Smaller font size for date */
                    color: #7f8c8d; /* Lighter color for date */
                    display: block; /* Move date to new line */
                }

                .message-detail {
                    background-color: #ffffff; /* White background for the detail */
                    border-radius: 10px;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                    padding: 15px;
                }

                .pagination {
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;
                }

                button {
                    margin: 0 10px;
                    padding: 10px 15px;
                    border: none;
                    border-radius: 5px;
                    background-color: #4CAF50; /* Green color */
                    color: white;
                    cursor: pointer;
                    transition: background 0.3s;
                }

                button:disabled {
                    background-color: #cccccc;
                    cursor: not-allowed;
                }

                button:hover:not(:disabled) {
                    background-color: #45a049; /* Darker green on hover */
                }

                .error {
                    color: #e74c3c; /* Red color for errors */
                    text-align: center;
                    margin-top: 20px;
                }
            `}</style>
        </div>
    );
};

export default ContactUsAdmin;
