import React, { useEffect, useState } from 'react';
import { fetchAllUsers, getUserByEmail, updateMembershipStatus } from '../service/AdminService';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize] = useState(10);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [membershipStatus, setMembershipStatus] = useState('');
    const [expireDate, setExpireDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const { users, total_count } = await fetchAllUsers(page, pageSize);
                setUsers(users || []);
                setTotalCount(total_count || 0);
            } catch (error) {
                console.error('Error loading users:', error);
            }
        };
        loadUsers();
    }, [page]);

    const handleUserClick = (user) => {
        setSelectedUser(user);
        setMembershipStatus(user.membership_status);
        setExpireDate(user.expire_date || '');
        setIsEditing(false);
    };

    const handleMembershipUpdate = async () => {
        try {
            await updateMembershipStatus(selectedUser.id, membershipStatus, expireDate);
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating membership:', error);
        }
    };

    const handleSearch = async () => {
        try {
            const response = await getUserByEmail(searchTerm);
            const { users, total_count } = response || {};
            setUsers(users || []);
            setTotalCount(total_count || 0);
            setPage(0); // Reset to the first page

            if (users && users.length === 1) {
                const user = users[0];
                setSelectedUser(user);
                setMembershipStatus(user.membership_status);
                setExpireDate(user.expire_date || '');
            } else {
                setSelectedUser(null);
            }
        } catch (error) {
            console.error('Error searching users:', error);
        }
    };

    const tableStyle = {
        width: '100%',
        borderCollapse: 'collapse',
        margin: '20px 0',
        borderRadius: '8px',
        overflow: 'hidden',
    };

    const thStyle = {
        backgroundColor: '#c8e6c9', // Lighter green
        color: '#2e7d32', // Darker green for text
        padding: '10px',
        borderBottom: '2px solid #ddd',
        textAlign: 'left',
    };

    const tdStyle = {
        padding: '10px',
        borderBottom: '1px solid #ddd',
        backgroundColor: '#f9fbe7', // Very light green
    };

    const buttonStyle = {
        padding: '10px 15px',
        backgroundColor: '#81c784', // Medium green
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginRight: '5px',
        transition: 'background-color 0.3s',
    };

    const buttonHoverStyle = {
        backgroundColor: '#66bb6a', // Darker green on hover
    };

    const userDetailStyle = {
        marginTop: '20px',
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    };

    const userDetailHeaderStyle = {
        fontSize: '1.8em',
        marginBottom: '15px',
        color: '#333',
        flexBasis: '100%',
    };

    const userDetailContentStyle = {
        margin: '10px 0',
        fontSize: '1em',
        color: '#555',
        flexBasis: '48%',
    };

    const editableFieldStyle = {
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0',
        flexBasis: '48%',
    };

    const fieldLabelStyle = {
        fontWeight: 'bold',
        marginRight: '10px',
        color: '#333',
    };

    const paginationStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
    };

    const formatCreateDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    return (
        <div style={{ padding: '20px', maxWidth: '900px' }}>
            <h1 style={{ textAlign: 'left' }}>User Management</h1>
            <div style={{ display: 'flex', marginBottom: '20px' }}>
                <input
                    type="text"
                    placeholder="Search by email or ID"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    style={{
                        flex: 1,
                        padding: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ddd',
                        marginRight: '10px',
                    }}
                />
                <button 
                    style={buttonStyle} 
                    onClick={handleSearch}
                    onMouseEnter={e => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                    onMouseLeave={e => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                >
                    Search
                </button>
            </div>
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={thStyle}>ID</th>
                        <th style={thStyle}>Name</th>
                        <th style={thStyle}>Email</th>
                        <th style={thStyle}>Membership</th>
                        <th style={thStyle}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id} style={{ cursor: 'pointer' }}>
                            <td style={tdStyle}>{user.id}</td>
                            <td style={tdStyle}>{user.name}</td>
                            <td style={tdStyle}>{user.email}</td>
                            <td style={tdStyle}>{user.membership_status}</td>
                            <td style={tdStyle}>
                                <button
                                    style={buttonStyle}
                                    onClick={() => handleUserClick(user)}
                                    onMouseEnter={e => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                                    onMouseLeave={e => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                                >
                                    View
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={paginationStyle}>
                <button 
                    style={buttonStyle} 
                    onClick={() => setPage(prev => Math.max(prev - 1, 0))} 
                    disabled={page === 0}
                    onMouseEnter={e => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                    onMouseLeave={e => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                >
                    Previous
                </button>
                <span> Page {page + 1} of {Math.ceil(totalCount / pageSize)} </span>
                <button 
                    style={buttonStyle} 
                    onClick={() => setPage(prev => prev + 1)} 
                    disabled={(page + 1) * pageSize >= totalCount}
                    onMouseEnter={e => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                    onMouseLeave={e => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                >
                    Next
                </button>
            </div>
            {selectedUser && (
                <div style={userDetailStyle}>
                    <h2 style={userDetailHeaderStyle}>User Details</h2>
                    <div style={userDetailContentStyle}>
                        <span style={fieldLabelStyle}>ID:</span> {selectedUser.id}
                    </div>
                    <div style={userDetailContentStyle}>
                        <span style={fieldLabelStyle}>Name:</span> {selectedUser.name}
                    </div>
                    <div style={userDetailContentStyle}>
                        <span style={fieldLabelStyle}>Email:</span> {selectedUser.email}
                    </div>
                    <div style={userDetailContentStyle}>
                        <span style={fieldLabelStyle}>Gender:</span> {selectedUser.gender}
                    </div>
                    <div style={userDetailContentStyle}>
                        <span style={fieldLabelStyle}>Is Astrologer:</span> {selectedUser.is_astrologer === 1 ? 'Yes' : 'No'}
                    </div>
                    <div style={userDetailContentStyle}>
                        <span style={fieldLabelStyle}>Since:</span> {formatCreateDate(selectedUser.create_date)}
                    </div>
                    <div style={editableFieldStyle}>
                        <span style={fieldLabelStyle}>Membership Status:</span>
                        {isEditing ? (
                            <select 
                                value={membershipStatus} 
                                onChange={e => setMembershipStatus(e.target.value)} 
                                style={{ flex: 1, padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                            >
                                <option value="Free">Free</option>
                                <option value="Gold">Gold</option>
                                <option value="Platinum">Platinum</option>
                            </select>
                        ) : (
                            <span>{selectedUser.membership_status}</span>
                        )}
                    </div>
                    <div style={editableFieldStyle}>
                        <span style={fieldLabelStyle}>Expire Date:</span>
                        {isEditing ? (
                            <input 
                                type="date" 
                                value={expireDate} 
                                onChange={e => setExpireDate(e.target.value)} 
                                style={{ flex: 1, padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                            />
                        ) : (
                            <span>{selectedUser.expire_date}</span>
                        )}
                    </div>
                    <div style={{ flexBasis: '100%', marginTop: '20px' }}>
                        <button 
                            style={buttonStyle} 
                            onClick={() => setIsEditing(prev => !prev)}
                            onMouseEnter={e => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                            onMouseLeave={e => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                        >
                            {isEditing ? 'Cancel' : 'Edit'}
                        </button>
                        {isEditing && (
                            <button 
                                style={{ ...buttonStyle, marginLeft: '10px' }} 
                                onClick={handleMembershipUpdate}
                                onMouseEnter={e => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                                onMouseLeave={e => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                            >
                                Save
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserManagement;
