import React, { useState } from 'react';
import {
    Drawer,
    AppBar,
    Toolbar,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Divider,
    CssBaseline,
    Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link
import { userLogout } from '../service/AdminService';

const textStyle = {
    fontFamily: 'Arial, sans-serif',
    fontSize: '27px',
    width: '250px',
    padding: 10,
    background: 'linear-gradient(to right, #FF9A9E, #FECFEF, #FAD0C4, #FECF7D, #F8E8A8, #B9FBC0, #E3F9F5, #D0F4C9, #C2E0FF, #B9E5F2, #AEEEEE)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    fontWeight: 'bold',
    display: 'inline-block',
    whiteSpace: 'nowrap',
};

const Sidebar = ({ onLogout }) => {
    const [open, setOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:1100px)');

    const toggleDrawer = () => {
        setOpen(!open);
    };

   
    return (
        <>
            <CssBaseline />
            <AppBar position="fixed" style={{ backgroundColor: '#4CAF50' }}>
                <Toolbar>
                    {isMobile && (
                        <IconButton edge="start" color="inherit" onClick={toggleDrawer}>
                            <MenuIcon />
                            
                        </IconButton>
                    )}
                    <ListItemText primary="Admin Dashboard" />
                    
                </Toolbar>
                
            </AppBar>

            <Drawer 
                anchor="left" 
                open={isMobile ? open : true} 
                onClose={isMobile ? toggleDrawer : undefined} 
                variant={isMobile ? 'temporary' : 'permanent'}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: '#004d00',
                        color: 'white',
                    },
                }}
            >
                <div
                    style={{ width: 250 }}
                    role="presentation"
                    onClick={isMobile ? toggleDrawer : undefined}
                    onKeyDown={isMobile ? toggleDrawer : undefined}
                >
                    <div style={textStyle}>Admin Dashboard</div>
                    <List>
                        {['Dashboard', 'User Management', 'Messages', 'Reports'].map((text) => (
                            <ListItem 
                                button 
                                key={text} 
                                component={Link} // Wrap ListItem with Link
                                to={text === 'Dashboard' ? '/' : `/${text.toLowerCase().replace(' ', '-')}`} // Set route
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: 'lightgreen',textFillColor:'black'
                                    },
                                }}
                            >
                                <ListItemText primary={text} style={{ color: 'white' }} />
                            </ListItem>
                        ))}
                    </List>
                    
                    <Divider style={{ backgroundColor: 'white' }} />
                    <Button color="inherit" style={{background:'lightgreen',color:'darkgreen',margin:50}} onClick={onLogout}>Logout</Button>
                </div>
            </Drawer>
        </>
    );
};

export default Sidebar;
