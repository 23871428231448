import axios from "axios";
import { 
    GET_USER_BY_ID_URL, 
    GET_USER_BY_EMAIL_URL, 
    UPDATE_MEMBERSHIP_URL, 
    FETCH_ALL_USERS_URL, 
    FETCH_CONTACT_US_URL,
    LOGIN_URL,
    LOGOUT_URL
} from "../config/constants";
axios.defaults.withCredentials = true;
// Fetch all users with pagination
export const fetchAllUsers = async (page = 0, pageSize = 10) => {
    try {
        const response = await axios.get(FETCH_ALL_USERS_URL, {
            params: {
                page,
                page_size: pageSize,
            },
            headers: {
                'Access-Control-Allow-Origin': '*',
                'X-Server': localStorage.getItem('userType'),
            },
        });
        return response.data; // Assuming it returns { users: [...], total_count: ... }
    } catch (error) {
        console.error('Error fetching all users:', error);
        throw error;
    }
};

// Fetch user details by ID
export const getUserById = async (userId) => {
    try {
        const response = await axios.get(`${GET_USER_BY_ID_URL}/${userId}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'X-Server': localStorage.getItem('userType'),
            },
        });
        return response.data; // User details
    } catch (error) {
        console.error('Error fetching user by ID:', error);
        throw error;
    }
};

// Fetch user details by email with pagination
export const getUserByEmail = async (email, page = 0, pageSize = 10) => {
    try {
        const response = await axios.get(`${GET_USER_BY_EMAIL_URL}/${email}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'X-Server': localStorage.getItem('userType'),
            },
            params: {
                page,      // Add page parameter
                pageSize,  // Add pageSize parameter
            },
        });
        return response.data; // User details
    } catch (error) {
        console.error('Error fetching user by email:', error);
        throw error;
    }
};


// Update membership status
export const updateMembershipStatus = async (userId, membershipStatus, expireDate) => {
    try {
        const response = await axios.patch(UPDATE_MEMBERSHIP_URL, {
            id: userId,
            membership_status: membershipStatus,
            expire_date: expireDate,
        }, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'X-Server': localStorage.getItem('userType'),
            },
        });
        return response.data; // Assuming it returns a success message
    } catch (error) {
        console.error('Error updating membership status:', error);
        throw error;
    }
};

export const fetchContactUsData = async ( page = 0, pageSize = 10) => {
    try {
        const response = await axios.get(`${FETCH_CONTACT_US_URL}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'X-Server': localStorage.getItem('userType'),
            },
            params: {
                page,      // Add page parameter
                pageSize,  // Add pageSize parameter
            },
        });
        return response.data; // User details
    } catch (error) {
        console.error('Error fetching user by email:', error);
        throw error;
    }
};

export const userLogin = async (formData) => {
    try {
        const response = await axios.post(LOGIN_URL, 
          formData
        );
        const { username,user_settings,user_type,email_verified } = response.data;
        // localStorage.setItem('access_token', access_token);
        localStorage.setItem('username',username);
        localStorage.setItem('loggedInTime', Date.now().toString());
        localStorage.setItem('userType',user_type);
        localStorage.setItem('emailVerified',email_verified);
        if(user_settings)
        localStorage.setItem('chartSettings',user_settings);
        
        return username
      } catch (error) {
        console.error('Login error:', error);
        const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
        throw errorMessage;
      }
}
export const userLogout = async () => {
    try {
        const response = await axios.post(LOGOUT_URL);
        console.log("---logout---"+JSON.stringify(response))
        // localStorage.removeItem('access_token');
        localStorage.removeItem('username');
        localStorage.setItem('loggedInTime',0)
        localStorage.removeItem('userType');
        localStorage.removeItem('emailVerified')
      } catch (error) {
        console.error('Logout error:', error);
        throw error
      }
}

