import React from 'react';
import UserManagement from '../admin/UserManagement';
import { Routes, Route } from "react-router-dom";
import ContactUsAdmin from '../admin/ContactUsAdmin';
import Dashboard from '../admin/Dashboard';

// Custom hook to determine if screen width is above a certain size
const useMediaQuery = (query) => {
    const [matches, setMatches] = React.useState(window.matchMedia(query).matches);

    React.useEffect(() => {
        const media = window.matchMedia(query);
        const listener = () => setMatches(media.matches);
        media.addListener(listener);
        return () => media.removeListener(listener);
    }, [query]);

    return matches;
};

const MainContent = () => {
    const isMdUp = useMediaQuery('(min-width: 768px)'); // Adjust the width as needed

    return (
        <div style={{ padding: '10px', marginLeft: isMdUp ? '100px' : '0' }}>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/user-management" element={<UserManagement />} />
                <Route path="/messages" element={<ContactUsAdmin />} />
            </Routes>
        </div>
    );
};

export default MainContent;
