// src/components/LoginForm.js
import React, { useState } from 'react';
import { userLogin } from '../service/AdminService';

const LoginForm = ({ onLoginSuccess }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            email:username,password:password
        }

        userLogin(formData)
                .then(username => {
                    // setOpenLogin(false);
                    // setOpenSignup(false);
                    // if(location.pathname.includes("verify-email"))
                    // navigation("/")
                    onLoginSuccess(username);
                })
                .catch(e => {
                    // setErrors(prev => ({ ...prev, invalidCredentials: true }));
                    // setErrorMessage(e)
                    setError('Invalid username or password.');
                })

       
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#e9f5e9',
            fontFamily: 'Arial, sans-serif',
        },
        form: {
            background: 'linear-gradient(135deg, #4CAF50 0%, #2e7d32 100%)',
            padding: '30px',
            borderRadius: '12px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
            width: '400px',
            color: '#fff',
        },
        input: {
            margin: '15px 0',
            padding: '12px',
            border: 'none',
            borderRadius: '6px',
            width: '100%',
            fontSize: '16px',
            outline: 'none',
            backgroundColor: '#ffffff',
            color: '#333',
        },
        button: {
            backgroundColor: '#81c784', // Light green
            color: '#fff',
            padding: '12px',
            border: 'none',
            borderRadius: '6px',
            cursor: 'pointer',
            fontSize: '16px',
            width: '100%',
            transition: 'background-color 0.3s',
        },
        buttonHover: {
            backgroundColor: '#66bb6a', // Darker green on hover
        },
        error: {
            color: '#ffcc00',
            marginTop: '10px',
            textAlign: 'center',
        },
        label: {
            fontWeight: 'bold',
            marginBottom: '5px',
            display: 'block',
        },
        heading: {
            textAlign: 'center',
            marginBottom: '20px',
            fontSize: '24px',
        },
    };

    return (
        <div style={styles.container}>
            <form style={styles.form} onSubmit={handleSubmit}>
                <h1 style={styles.heading}>Admin Login</h1>
                <label style={styles.label}>Username:</label>
                <input
                    type="text"
                    style={styles.input}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <label style={styles.label}>Password:</label>
                <input
                    type="password"
                    style={styles.input}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button
                    type="submit"
                    style={styles.button}
                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor)}
                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = styles.button.backgroundColor)}
                >
                    Login
                </button>
                {error && <p style={styles.error}>{error}</p>}
            </form>
        </div>
    );
};

export default LoginForm;
