import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';

const Header = () => {
    return (
        <AppBar position="static" style={{ backgroundColor: '#4CAF50' }}>
            <Toolbar>sss
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    Admin Dashboard
                </Typography>
                
            </Toolbar>
        </AppBar>
    );
};

export default Header;
