import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Box, Card, CardContent, Grid } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People'; // User icon
import MessageIcon from '@mui/icons-material/Message'; // Message icon

const Dashboard = () => {
    return (
        <div>
            <AppBar position="static" style={{ backgroundColor: '#4CAF50' }}>
                <Toolbar>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        {/* Changed style for the Dashboard title */}
                        <span style={{ fontWeight: 'bold', fontSize: '1.5rem', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)' }}>
                            Dashboard
                        </span>
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container style={{ marginTop: '20px' }}>
                <Box textAlign="center" mb={4}>
                    <Typography variant="h4" style={{ color: '#333' }}>
                        Welcome to the Admin Dashboard!
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: '#555' }}>
                        Manage users and messages from this central hub.
                    </Typography>
                </Box>

                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <Card component={Link} to="/user-management" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <CardContent>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <PeopleIcon style={{ fontSize: 50, color: '#4CAF50' }} />
                                    <Typography variant="h5" style={{ marginTop: '10px' }}>
                                        User Management
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Card component={Link} to="/messages" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <CardContent>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <MessageIcon style={{ fontSize: 50, color: '#4CAF50' }} />
                                    <Typography variant="h5" style={{ marginTop: '10px' }}>
                                        Messages
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

            <style jsx>{`
                body {
                    margin: 0;
                    font-family: 'Arial', sans-serif;
                    background-color: #f7fff7; /* Light green background */
                }
            `}</style>
        </div>
    );
};

export default Dashboard;
