import React, { useEffect, useState } from 'react';
import { CssBaseline, Container } from '@mui/material';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import MainContent from './components/MainContent';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import LoginForm from './admin/LoginForm';
import { userLogout } from './service/AdminService';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const expirationDate = localStorage.getItem('expirationDate');
        if (expirationDate && Date.now() > expirationDate) {
            handleLogout(); // Automatically logout if expired
        } else {
            const userData = localStorage.getItem('userData');
            if (userData) {
                setIsAuthenticated(true); // Set authenticated state if user data exists
            }
        }
    }, []);

    const handleLoginSuccess = (data) => {
        const expirationTime = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
        const expirationDate = Date.now() + expirationTime;

        localStorage.setItem('userData', JSON.stringify(data));
        localStorage.setItem('expirationDate', expirationDate);

        setIsAuthenticated(true);
    };

    const handleLogout = () => {
      
        userLogout().then(() => {
            localStorage.removeItem('userData');
            localStorage.removeItem('expirationDate');
            setIsAuthenticated(false);
          
        });
    };

    return (
        <div className="app">
            <BrowserRouter basename='/admin'>
                <CssBaseline />
                {!isAuthenticated ? (
                    <LoginForm onLoginSuccess={handleLoginSuccess} />
                ) : (
                    <>
                        <Header onLogout={handleLogout} />
                        <div className="main-container">
                            <Sidebar onLogout={handleLogout} />sss
                            <Container component="main" className="content">
                                <MainContent />
                            </Container>
                        </div>
                    </>
                )}
            </BrowserRouter>
        </div>
    );
};

export default App;
